@import 'form.scss';
.d-social-login {
    margin-bottom: 15px;
    margin-left: -2px;
    margin-right: -2px;
    display: inline-block;
    &__button-list {
        display: flex;
        flex-wrap: wrap;
    }
    &__label {
        align-self: center;
        display: none;
    }
    &__button {
        color: #fff;
        display: flex;
        -webkit-border-radius: 3px;
        -moz-border-radius: 3px;
        border-radius: 3px;
        box-shadow: 0 1px 0 rgba(0, 0, 0, 0.10);
        -webkit-transition: all .4s ease;
        -moz-transition: all .4s ease;
        -ms-transition: all .4s ease;
        -o-transition: all .4s ease;
        transition: all .4s ease;
        margin: 2px;
        margin-left: 2px !important;
        &__label {
            align-self: center;
            color: #fff;
            text-shadow: none;
            &:focus,
            &:active {
                text-decoration: none;
            }
        }
        &__icon {
            border-right: 1px solid rgba(255, 255, 255, 0.3);
            img {
                display: inline-block;
                -webkit-background-size: contain;
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center;
                height: 100%;
                width: 100%;
            }
            &__preloader {
                display: none;
                max-width: 100%;
                max-height: 100%;
            }
        }
        &:hover {
            box-shadow: inset 0 -2px 0 rgba(0, 0, 0, 0.20);
            color: #fff;
            .d-social-login__button__label {
                color: #fff;
                text-decoration: none;
            }
        }
        &:focus,
        &:active {
            color: #fff;
            text-decoration: none;
            .d-social-login__button__label {
                color: #fff;
                text-decoration: none;
            }
        }
        &--loading {
            .d-social-login__button__icon__preloader {
                display: block;
            }
            .d-social-login__button__icon>img {
                display: none;
            }
        }
    }
    &--icons {
        .d-social-login {
            &__label {
                display: flex;
                padding: 9px 10px 0px 0px;
                height: 40px;
                font-size: 12px;
                color: #888;
            }
            &__button {
                &__label {
                    display: none;
                }
                &__icon {
                    border: none;
                    padding: 4px;
                    max-height: 34px;
                    max-width: 34px;
                    &__preloader {
                        padding: 2px 1px;
                        &>div {
                            width: 25px;
                            height: 25px;
                        }
                    }
                }
            }
        }
    }
    &--small {
        .d-social-login {
            &__button {
                &__label {
                    padding: 3px;
                    font-size: 10px;
                }
                &__icon {
                    padding: 2px;
                    max-height: 26px;
                    max-width: 26px;
                    &__preloader {
                        &>div {
                            width: 22px;
                            height: 22px;
                        }
                    }
                }
            }
        }
    }
    &--medium {
        .d-social-login {
            &__button {
                &__label {
                    padding: 4px;
                    font-size: 12px;
                }
                &__icon {
                    padding: 4px;
                    max-height: 32px;
                    max-width: 32px;
                    &__preloader {
                        &>div {
                            width: 24px;
                            height: 24px;
                        }
                    }
                }
            }
        }
    }
    &--large {
        .d-social-login {
            &__button {
                &__label {
                    padding: 4px 8px;
                    font-size: 13px;
                }
                &__icon {
                    padding: 4px;
                    max-height: 34px;
                    max-width: 34px;
                    &__preloader {
                        &>div {
                            width: 26px;
                            height: 26px;
                        }
                    }
                }
            }
        }
    }
    &--huge {
        .d-social-login {
            &__button {
                &__label {
                    padding: 10px 20px;
                    font-size: 15px;
                }
                &__icon {
                    padding: 4px;
                    max-height: 43px;
                    max-width: 43px;
                    &__preloader {
                        &>div {
                            width: 32px;
                            height: 32px;
                        }
                    }
                }
            }
        }
    }
}

header,
nav {
    .d-social-login {
        margin-bottom: 0px;
        display: inline-block;
    }
}